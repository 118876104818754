.group-child37 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0 0 var(--br-xs) var(--br-xs);
  background-color: var(--color-whitesmoke-200);
  width: 1468px;
  height: 63px;
}
.span {
  font-weight: 500;
}
.to {
  color: var(--color-gray-200);
}
.to-50-of-container {
  position: absolute;
  top: 0;
  left: 0;
  letter-spacing: 0.4px;
  white-space: pre-wrap;
}
.span3 {
  font-weight: 500;
  color: var(--color-black);
}
.page-1-of-container {
  position: absolute;
  top: 0;
  left: 70.5px;
  letter-spacing: 0.4px;
  white-space: pre-wrap;
}
.vector-icon3,
.vector-icon4 {
  position: absolute;
  top: 5px;
  left: 26.2px;
  width: 9.2px;
  height: 11.8px;
  object-fit: cover;
}
.vector-icon4 {
  top: 3.5px;
  left: -11.1px;
  width: 7.4px;
  height: 12.4px;
}
.i {
  position: absolute;
  top: 4px;
  left: -15px;
  letter-spacing: 0.4px;
  font-weight: 500;
  display: inline-block;
  width: 2.2px;
  height: 11.8px;
}
.group-child38,
.vector-group,
.vector-icon5 {
  position: absolute;
  width: 14.2px;
  height: 18.8px;
}
.vector-group {
  top: 2px;
  left: 0;
  font-size: var(--font-size-mini);
  color: var(--color-black);
}
.group-child38,
.vector-icon5 {
  top: 2.2px;
  left: 224.7px;
  object-fit: contain;
}
.vector-icon5 {
  top: 5.1px;
  left: 192.5px;
  width: 8.2px;
  height: 13.9px;
}
.line-icon,
.page-1-of-2-parent {
  position: absolute;
  top: 0;
  height: 23px;
}
.page-1-of-2-parent {
  left: 197px;
  width: 239px;
  color: var(--color-gray-200);
}
.line-icon {
  left: 157.5px;
  width: 0;
  object-fit: contain;
}
.group-wrapper,
.to-50-of-52-parent {
  position: absolute;
  top: 0;
  left: 0;
  width: 436px;
  height: 23px;
}
.group-wrapper {
  top: 20px;
  left: 993px;
}
.rectangle-parent2 {
  position: absolute;
  top: 790px;
  left: 35px;
  width: 1468px;
  height: 63px;
  text-align: left;
  font-size: var(--font-size-lg);
  color: var(--color-black);
  font-family: var(--font-outfit);
}
