.eta-bulk-upload-child {
  position: absolute;
  top: 0;
  left: 0;
  width: 1728px;
  height: 1000px;
  object-fit: cover;
}
.eta-bulk-upload-item {
  position: absolute;
  top: 211px;
  left: 636px;
  border-radius: 12px;
  background-color: #FFFFFF;
  width: 455px;
  height: 554px;
}
.group-icon {
  position: absolute;
  top: 265px;
  left: 798.7px;
  width: 126.7px;
  height: 76px;
  object-fit: cover;
}
.welcome-to-sourcing {
  position: absolute;
  top: 367px;
  left: 709px;
  font-weight: 600;
}
.login {
  position: relative;
  font-weight: 500;
  display: inline-block;
  width: 118px;
  flex-shrink: 0;
}
.login-wrapper {
  position: absolute;
  top: 685px;
  left: 764px;
  border-radius: 6px;
  background-color: #003049;
  width: 198px;
  height: 52px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: var(--color-white);
}
.please-use-your {
  position: absolute;
  top: 411px;
  left: 665px;
  font-size: var(--font-size-base);
  color: rgba(0, 0, 0, 0.6);
}
/* .group-child, */
.user-name {
  position: absolute;
  top: 0;
  left: 0;
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  /* font-weight: bold; */
}
/* .group-child {
  border-radius: 4px;
  background-color: rgba(238, 238, 238, 0.7);
  width: 359px;
  height: 52px;
} */
.manjunath {
  position: absolute;
  top: 9px;
  left: 1px;
  font-weight: 500;
  width: 359px;
  height: 52px;
  border-radius: 4px;
  background: rgba(238, 238, 238, 0.70);
  color: #000000;
  font-size: 16px;
  line-height: normal;
  border: none;
  padding: 20px;
}
.rectangle-parent {
  position: absolute;
  top: 31px;
  left: 0;
  width: 359px;
  height: 52px;
  font-size: var(--font-size-base);
}
.user-name-parent {
  position: absolute;
  top: 454px;
  left: 684px;
  width: 359px;
  height: 83px;
  text-align: left;
}
/* .group-item, */
.group-parent {
  position: absolute;
  width: 359px;
  height: 52px;
}
/* .group-item {
  top: 0;
  left: 0;
  object-fit: cover;
} */
.group-parent {
  top: 31px;
  left: 1px;
  font-size: var(--font-size-base);
}
.password-parent {
  position: absolute;
  top: 557px;
  left: 683px;
  width: 360px;
  height: 83px;
  text-align: left;
}
.eta-bulk-upload {
  background-color: var(--color-white);
  width: 1728px;
  height: 1000px;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-lg);
  color: var(--color-black);
  font-family: var(--font-outfit);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.eye-icon{
  position: absolute;
  top: 57px;
  left: 320px;
  width: 23px;
  height: 14px;
}
body{
  font-family: "Nunito",sans-serif;
}
