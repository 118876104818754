/* Table.css */

/* .custom-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .custom-table th, .custom-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .custom-table th {
    background-color: #f2f2f2;
  }
  
  .custom-table tbody tr:hover {
    background-color: #f5f5f5;
  } */

  /* Table.css */ 

table {
    width: 65%; /* Adjust the width as needed */
    margin: auto;
    border-collapse: collapse;
    margin-top: 50px; /* Add margin-top to adjust the space from the top */
  }
  
  th, td {
    border: 2px solid #dddddd;
    text-align: center;
    padding: 8px;
  }
  
  th {
    background-color: #f2f2f2;
  }
  
  