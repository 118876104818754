.group-child35 {
  background-color: var(--color-white);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 1728px;
  height: 100px;
}
.group-child35,
.group-child36,
.group-icon1 {
  position: absolute;
  top: 0;
  left: 0;
}
.group-icon1 {
  width: 80px;
  height: 48px;
  object-fit: cover;
  left: 30px;
}
.group-child36 {
  border-radius: var(--br-3xl);
  background-color: var(--color-whitesmoke-100);
  width: 82px;
  height: 44px;
}
.vector-icon2 {
  position: absolute;
  top: 8px;
  left: 31px;
  width: 9px;
  height: 4.5px;
  object-fit: cover;
}
.en,
.vector-parent {
  position: absolute;
  top: 3px;
  left: 6px;
  color: #333;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 36px;
  background: #F8F8F8;
}
.vector-parent {
  top: 10px;
  left: 20px;
  width: 42px;
  height: 23px;
}
.group-group,
.rectangle-parent1 {
  position: absolute;
  top: 2px;
  left: 1319px;
  width: 82px;
  height: 44px;
}
.group-group {
  top: 0;
  left: 0;
  width: 1401px;
  height: 48px;
}
.trackstylishopcom {
  position: absolute;
  top: 11px;
  left: 0;
  letter-spacing: 0.4px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.60);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
}
.ellipse-icon {
  position: absolute;
  top: 0;
  left: 177px;
  width: 38px;
  height: 35px;
  object-fit: cover;
  border-radius: 41px;
}
.trackstylishopcom-parent {
  position: absolute;
  top: 3px;
  float: right;
  text-align: center;
  font-size: var(--font-size-sm);
  color: var(--color-gray-200);
}
.group-div,
.group-parent {
  position: absolute;
  top: 26px;
  left: 40px;
  width: 1648px;
  height: 48px;
}
.group-div {
  top: 0;
  left: 0;
  width: 1728px;
  height: 100px;
  text-align: left;
  font-size: var(--font-size-lg);
  color: var(--color-darkslategray);
  font-family: var(--font-outfit);
}

.dropbtn {
  background-color: #3498db;
  color: white;
  padding: 10px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

/* Style the dropdown content (hidden by default) */
.dropdown-content {
  margin-top: 50px;
  padding-inline: 15px;
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Style links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #f1f1f1;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Position the dropdown content */
.dropdown-content a:last-child {
  border-top: 1px solid #ddd;
}
.dropdown-content-logout{
  padding: 33px;;
}
